import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';

const Services = () => {
  const { services } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="services">
      <Container>
        <div className="service-wrapper">
          <Title title="Services" />
          {services.map((service) => {
            const { title, description, skills, id } = service;

            return (
              <Row key={id} className='py-4' style={{ justifyContent: 'space-around' }}>
                <Col md={7} sm={6}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={200}
                    distance="30px"
                  >
                    <div className="services-wrapper__text">
                      <h3 className="services-wrapper__text-title">{title}</h3>
                      <div>
                        <p>{description || ''}</p>
                      </div>
                    </div>
                  </Fade>
                </Col>
                <Col md={4} sm={6}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={400}
                    distance="30px"
                  >
                    <div className="services-wrapper__skills p-4">
                      {skills.map((skill, index) => {
                        return <p key={index}>{skill}</p>;
                      })}
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Services;
